export default {
  path: "/account",
  component: () => import(/* webpackChunkName: "AccountRoot" */ "../pages/Account/AccountRoot.vue"),
  // beforeRouteEnter: isAuthorized,
  children: [
    {
      path: "/",
      redirect: { name: "AccountNotifications" }
    },
    {
      path: "notifications",
      name: "AccountNotifications",
      component: () =>
        import(
          /* webpackChunkName: "AccountNotifications" */ "../pages/Account/Notifications/Notifications.vue"
        )
    },
    {
      path: "settings",
      name: "AccountSettings",
      component: () =>
        import(
          /* webpackChunkName: "AccountNotifications" */ "../pages/Account/Settings/Settings.vue"
        )
    }
  ]
};
