import { getQuotationForTicket, manageQuoteState, replaceContractor } from "@/api";

export default {
  namespaced: true,
  state: {
    quotations: {}
  },
  getters: {
    expertsAll(state) {
      return state.quotations["experts"] || [];
    },
    techniciansAll(state) {
      return state.quotations["technicians"] || [];
    },
    experts(state, getters) {
      return getters.expertsAll.filter(
        user => user.aasm_state == "started" || user.aasm_state != "declined"
      );
    },
    technicians(state, getters) {
      return getters.techniciansAll.filter(
        user => user.aasm_state == "started" || user.aasm_state != "declined"
      );
    },
    contractors(state, getters) {
      return [...getters.experts, ...getters.technicians];
    },
    selectedQuotes(state, getters) {
      return [...getters.expertsAll, ...getters.techniciansAll].filter(
        user =>
          user.aasm_state == "selected" ||
          user.aasm_state == "offered" ||
          user.aasm_state == "signed"
      );
    }
  },
  actions: {
    getQuotationForTicket({ commit }, orderId) {
      return new Promise((resolve, reject) => {
        getQuotationForTicket(orderId)
          .then(resp => {
            commit("setQuotations", resp.data);
            // commit("setQuotations", mockedData);
            resolve(resp);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    sendSelectUser(state, id) {
      return manageQuoteState(id, "select");
    },
    sendReplaceUser(quoteId, ticketId) {
      return replaceContractor(quoteId, ticketId);
    }
  },
  mutations: {
    setQuotations(state, data) {
      state.quotations = data;
    }
  }
};
