import {
  login as apiLogin,
  logout as apiLogout,
  getSession as apiSession,
  setToken,
  signup as apiSignup
} from "@/api";

export function clearSession() {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  setToken("");
}

export async function logout() {
  await apiLogout();
  clearSession();
}

export function autologin() {
  const token = localStorage.getItem("auth");
  if (token) {
    setToken(token);
  }
}

export function setUserAuthToken(token) {
  localStorage.setItem("auth", token);
  setToken(token);
}

export async function login(creds) {
  const result = await apiLogin(creds);
  localStorage.setItem("user", JSON.stringify(result.data.user));
  setUserAuthToken(result.data.session.token);
}

export async function getSession() {
  const result = await apiSession();
  localStorage.setItem("user", JSON.stringify(result.data.user));
}

export async function loginViaToken(token) {
  clearSession();
  setUserAuthToken(token);
  return await getSession();
}

export async function signup(creds) {
  const result = await apiSignup(creds);
  setToken(result.data.token);
}

export function me() {
  return !!localStorage.getItem("auth");
}

export function getUserInfo() {
  const userInfo = localStorage.getItem("user") || "{}";
  return JSON.parse(userInfo);
}

export function updateAdditionalRole(role: never, toAdd = true) {
  const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
  let roles = [];

  if (Object.prototype.hasOwnProperty.call(userInfo, "enabled_profiles")) {
    roles = userInfo["enabled_profiles"];
  }

  if (!toAdd) {
    const index = roles.indexOf(role);

    if (index !== -1) {
      roles.splice(index, 1);
    }
  } else {
    if (roles.indexOf(role) == -1) {
      roles.push(role);
    }
  }

  userInfo["enabled_profiles"] = roles;
  localStorage.setItem("user", JSON.stringify(userInfo));
}

export function setAsCustomer(isCustomer = undefined) {
  if (isCustomer != undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    updateAdditionalRole("customer", isCustomer);
  }
}
