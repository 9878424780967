import { sendChangePassData } from "@/api";

export default {
  namespaced: true,
  state: {
    email: "",
    newEmail: "",
    emailFormOpened: false,
    passFormOpened: false,
    currentHomePage: ""
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendChangePassData({ commit }, { oldPass, newPass, confPass }) {
      return new Promise(() => {
        sendChangePassData({ oldPass, newPass, confPass });
      });
    }
  },
  mutations: {
    setEmail(state, value) {
      state.email = value;
    },
    setNewEmail(state, value) {
      state.newEmail = value;
    },
    setEmailFormState(state, value) {
      state.emailFormOpened = value;
    },
    setPassFormState(state, value) {
      state.passFormOpened = value;
    }
  }
};
