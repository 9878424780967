import { sendStripeToken, getAllPaymentCards } from "@/api";

export default {
  namespaced: true,
  state: {
    formOpened: false,
    // MOCK!
    cards: []
  },
  actions: {
    sendStripeToken({ commit }, token) {
      return new Promise(resolve => {
        sendStripeToken(token).then(() => {
          resolve();
        });
      });
    },
    getAllPaymentCards({ commit }) {
      return new Promise(resolve => {
        getAllPaymentCards().then(resp => {
          commit("setCards", resp.data);

          resolve();
        });
      });
    }
  },
  mutations: {
    setFormState(state, value) {
      state.formOpened = value;
    },
    setCards(state, cards) {
      state.cards = cards;
    }
  }
};
