import { getConversation, sendMessage, getPresignedLink } from "@/api";
import uid from "uid";

export default {
  namespaced: true,
  state: {
    conversation: []
  },
  actions: {
    getConversationData({ commit }, conversationId) {
      return new Promise((resolve, reject) => {
        getConversation(conversationId)
          .then(res => {
            commit("setConversation", res.data);
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    createForm(state, messageData) {
      return new Promise(resolve => {
        const form = new FormData();
        form.append("message[body]", messageData.message);

        if (messageData.file) {
          form.append("message[attachments][]", messageData.file);
        }
        resolve(form);
      });
    },
    sendMessage({ dispatch }, { conversationId, form }) {
      return new Promise((resolve, reject) => {
        sendMessage(conversationId, form)
          .then(() => {
            return dispatch("getConversationData", conversationId);
          })
          .then(() => {
            resolve();
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    sendFile(store, file) {
      return new Promise((resolve, reject) => {
        if (file) {
          const fileParams = {
            file_name: uid(),
            file_type: file.name.split(".").pop(),
            file_instance_name: "ticket_chat"
          };
          getPresignedLink(fileParams, file)
            .then(({ url }) => {
              resolve(url.split("?")[0]);
            })
            .catch(e => {
              reject(e);
            });
        } else {
          resolve("");
        }
      });
    }
  },
  mutations: {
    setConversation(state, data) {
      state.conversation = data;
    },
    clearConversation(state) {
      state.conversation = [];
    }
  }
};
