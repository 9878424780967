import { getAllNotifs } from "@/api";
import { me } from "@/auth/auth";

export default {
  namespaced: true,
  state: {
    allNotifs: ""
  },
  mutations: {
    setNotifs(state, data) {
      state.allNotifs = data;
    }
  },
  actions: {
    getAllNotifs({ commit }) {
      const isAuthorised = me();
      if (isAuthorised) {
        getAllNotifs().then(res => {
          commit("setNotifs", res.data);
        });
      } else {
        console.log("Not authorised");
      }
    }
  }
};
