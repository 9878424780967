export default {
  path: "/projects",
  component: () => import(/* webpackChunkName: "Projects" */ "../pages/Projects/Projects.vue"),
  children: [
    {
      path: "/",
      redirect: { name: "ProjectsOrdersNew" }
    },
    {
      path: "orders",
      props: { btn: "orders" },
      component: () =>
        import(/* webpackChunkName: "ProjectsOrders" */ "../pages/Projects/ProjectsTickets.vue"),
      children: [
        {
          path: "/",
          redirect: { name: "ProjectsOrdersNew" }
        },
        {
          path: "new",
          name: "ProjectsOrdersNew",
          props: { tab: "new" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsOrdersNew" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        },
        {
          path: "in_progress",
          name: "ProjectsOrdersInProgress",
          props: { tab: "in_progress" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsOrdersInProgress" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        },

        {
          path: "done",
          name: "ProjectsOrdersDone",
          props: { tab: "done" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsOrdersDone" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        }
      ]
    },
    {
      path: "bids",
      props: { btn: "bids" },
      component: () =>
        import(/* webpackChunkName: "ProjectsBids" */ "../pages/Projects/ProjectsTickets.vue"),
      children: [
        {
          path: "/",
          redirect: { name: "ProjectsBidsNew" }
        },
        {
          path: "new",
          name: "ProjectsBidsNew",
          props: { tab: "new" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsBidsNew" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        },
        {
          path: "in_progress",
          name: "ProjectsBidsInProgress",
          props: { tab: "in_progress" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsBidsInProgress" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        },

        {
          path: "done",
          name: "ProjectsBidsDone",
          props: { tab: "done" },
          component: () =>
            import(
              /* webpackChunkName: "ProjectsBidsDone" */ "../pages/Projects/ProjectsTicketsList.vue"
            )
        }
      ]
    },
    {
      path: "archive",
      name: "Archive",
      props: { btn: "archive" },
      component: () =>
        import(/* webpackChunkName: "ProjectsArchive" */ "../pages/Projects/Archive.vue")
    }
  ]
};
