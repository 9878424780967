import { createQuotation } from "@/api";
import BaseContractModal from "@/store/BaseContractModal";

export default {
  namespaced: true,
  state: {
    ...BaseContractModal().state
  },
  actions: {
    createForm({ state }) {
      return new Promise(resolve => {
        const form = new FormData();
        const general = "quotation[general]";
        const urgency = "quotation[urgency]";
        const requestedService = "quotation[requested_service]";
        const partsAndMaterials = "quotation[parts_and_materials]";
        const limitedWarranty = "quotation[limited_warranty]";
        const handlingAndShipping = "quotation[handling_and_shipping]";
        const summary = "quotation[summary]";

        form.append("quotation[quote_request_id]", state.quoteId);

        form.append(`${general}[payment_delay]`, state.paymentDelay);
        form.append(`${general}[minimum_service_amount]`, state.minimumServiceAmount);
        form.append(`${general}[estimated_service_time]`, state.estimatedServiceTime);
        form.append(`${general}[working_hours_from]`, state.workScheduleFrom);
        form.append(`${general}[working_hours_to]`, state.workScheduleTo);

        form.append(`${urgency}[quantity]`, state.quantity);
        form.append(`${urgency}[units]`, state.textHint);

        if (
          state.requestedServices.selectedConditions &&
          state.requestedServices.selectedConditions.length
        ) {
          state.requestedServices.selectedConditions.forEach(item => {
            form.append(`${requestedService}[services][]`, item.id);
          });
        }
        form.append(`${requestedService}[service_count]`, state.requestedServices.labourQuantity);
        form.append(`${requestedService}[service_unit_type]`, state.requestedServices.unitsOne);
        form.append(`${requestedService}[service_rate]`, state.requestedServices.hourlyPrice);
        form.append(`${requestedService}[service_currency]`, state.requestedServices.unitsTwo);

        form.append(`${partsAndMaterials}[labour]`, state.partsMaterials.labourQuantity);
        form.append(`${partsAndMaterials}[labour_units]`, state.partsMaterials.unitsOne);
        form.append(`${partsAndMaterials}[price]`, state.partsMaterials.hourlyPrice);
        form.append(
          `${partsAndMaterials}[price_units]`,
          state.partsMaterials.unitsTwo || "per_pcs"
        );

        form.append(`${limitedWarranty}[labour]`, state.limitedWarranty.labourQuantity);
        form.append(`${limitedWarranty}[labour_units]`, state.limitedWarranty.unitsOne);
        form.append(`${limitedWarranty}[price]`, state.limitedWarranty.hourlyPrice);
        form.append(`${limitedWarranty}[price_units]`, state.limitedWarranty.unitsTwo || "per_pcs");
        form.append(`${limitedWarranty}[warranty_term]`, state.limitedWarranty.warrantyTerm);

        form.append(`${handlingAndShipping}[labour]`, state.handlingShipping.labourQuantity);
        form.append(`${handlingAndShipping}[labour_units]`, state.handlingShipping.unitsOne);
        form.append(`${handlingAndShipping}[price]`, state.handlingShipping.hourlyPrice);
        form.append(`${handlingAndShipping}[price_units]`, state.handlingShipping.unitsTwo);
        form.append(`${handlingAndShipping}[incoterms]`, state.handlingShipping.incoterms);
        const subtotal = (
          +state.prices.total -
          +state.prices.vat -
          +state.prices.platformFee
        ).toString();

        form.append(`${summary}[subtotal]`, subtotal);
        form.append(`${summary}[vat]`, state.prices.vat);
        form.append(`${summary}[total]`, state.prices.total);

        resolve(form);
      });
    },
    createNewQuotation({ dispatch }) {
      return dispatch("createForm").then(form => {
        return createQuotation(form).then(() => {
          document.location.reload();
        });
      });
    }
  },
  mutations: {
    ...BaseContractModal().mutations
  }
};
