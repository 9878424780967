export default {
  namespaced: true,
  state: {
    isModalOpened: false,
    headerNbr: "",
    repairType: "",
    interactionLangs: "",
    workConditions: "",
    addConditions: [],
    manufacturerInfo: ""
  },
  mutations: {
    closeModal(state) {
      state.isModalOpened = false;
    },
    openModal(state) {
      state.isModalOpened = true;
    },
    setPurchaseOrderData(state, data) {
      state.headerNbr = data.number;
      state.addConditions = data.additional_conditions || [];
      state.workConditions = data.work_conditions || [];
      state.manufacturerInfo = data.title;
      state.interactionLangs = data.languages || [];
      state.repairType = data.subject_description ? data.subject_description.repair_type : "";
    },
    clearPurchaseOrderData(state) {
      state.isModalOpened = false;
      state.headerNbr = "";
      state.repairType = "";
      state.interactionLangs = "";
      state.workConditions = [];
      state.addConditions = [];
      state.manufacturerInfo = "";
    }
  }
};
