<template>
  <v-app>
    <div>
      <v-app-bar color="" dark height="74">
        <router-link to="/search-expert"
          ><v-img src="@/assets/CluExpress.svg" max-width="190"></v-img
        ></router-link>

        <template v-if="isAuthorised">
          <v-btn color="primary" small class="ml-4" to="/create-order" v-if="isCustomer">
            {{ $t(`New Order`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text small :to="isCustomer ? '/projects/orders' : '/projects/bids'">
            {{ isCustomer ? $t("My Projects") : $t("My Jobs") }}
          </v-btn>
          <v-btn
            text
            small
            link
            target="_blank"
            href="https://docs.google.com/document/d/1-Y8XrNNQ_E-EMcx8IgcjLflSbeeAtpRzJeurYi_5rK0/edit?usp=sharing"
          >
            {{ $t(`FAQ`) }}
          </v-btn>
          <v-badge
            color="red"
            overlap
            :content="notifCount"
            offset-x="25"
            offset-y="25"
            bordered
            v-if="notifCount"
          >
            <v-btn icon to="/account/notifications">
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-btn icon to="/account/notifications" v-if="!notifCount">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
          <!-- TODO: add localization  -->
          <translate-modal></translate-modal>
          <v-menu left bottom offset-y tile>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" class="ma-1">
                <v-avatar v-if="userInfo.avatar_url">
                  <v-img :src="userInfo.avatar_url"></v-img>
                </v-avatar>
                <v-icon v-else>mdi-account</v-icon>
              </v-btn>
            </template>

            <v-list dark>
              <v-list-item to="/profile">
                <v-list-item-title>{{ $t("Profile") }}</v-list-item-title>
              </v-list-item>
              <v-list-item to="/account/settings">
                <v-list-item-title>{{ $t("Settings") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-if="!isAuthorised">
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            link
            target="_blank"
            href="https://docs.google.com/document/d/1-Y8XrNNQ_E-EMcx8IgcjLflSbeeAtpRzJeurYi_5rK0/edit?usp=sharing"
          >
            {{ $t(`FAQ`) }}
          </v-btn>
          <!-- TODO: add localization  -->
          <translate-modal></translate-modal>
          <v-btn color="primary" small :to="{ name: 'login' }">
            {{ $t(`Sign in`) }}
          </v-btn>
        </template>
      </v-app-bar>
    </div>
    <v-main class="main-bgc">
      <v-container fluid class="px-11 py-7">
        <router-view></router-view>
      </v-container>
      <cookie-law theme="dark-lime--rounded" v-on:accept="cookieAccepted()">
        <div slot="message">
          We are using cookie! What does it mean? <a href="#" class="white--text">Click here</a>
        </div>
      </cookie-law>
    </v-main>
  </v-app>
</template>
<script>
import { logout as logoutApi, me, getUserInfo, clearSession } from "@/auth/auth";
import { includes } from "lodash";
import { mapActions, mapState } from "vuex";
import TranslateModal from "@/components/TranslateModal";
import CookieLaw from "vue-cookie-law";
import { acceptCookies } from "@/api";

export default {
  components: { TranslateModal, CookieLaw },
  data: () => ({
    notifCount: 0,
    lang: "en",
    userInfo: getUserInfo() || {},
    isAuthorised: me() || false,
    isCustomer: false,
    defaultLanguageCode: "en"
  }),
  methods: {
    cookieAccepted() {
      acceptCookies()
        .then(e => {
          console.log(e.data);
        })
        .catch(e => {
          console.log(e.data);
        });
    },
    logout() {
      logoutApi()
        .then(() => {
          clearSession();
          document.location.pathname = "/login";
        })
        .catch(() => {
          clearSession();
          this.$router.push("/login");
        })
        .finally(() => {
          clearSession();
        });
    },
    getNotificationsCount() {
      this.getAllNotifs()
        .then(() => {
          this.notifCount = this.allNotifs ? this.allNotifs.filter(i => !i.read).length : 0;
        })
        .catch(err => {
          console.error(err);
        });
    },
    checkCustomerAccess() {
      return includes(this.userInfo.enabled_profiles, "customer");
    },
    ...mapActions("AllNotifsStore", ["getAllNotifs"])
  },
  beforeRouteUpdate(to, from, next) {
    this.getNotificationsCount();
    this.userInfo = getUserInfo() || {};
    this.isAuthorised = me() || false;
    this.isCustomer = this.checkCustomerAccess();
    next();
  },
  computed: {
    // isAuthorised() {
    //   return me();
    // },
    // userInfo() {
    //   return getUserInfo() || {};
    // },
    // isCustomer() {
    //   return includes(this.userInfo.enabled_profiles, "customer");
    // }
    ...mapState("AllNotifsStore", ["allNotifs"])
  },
  mounted() {
    this.isCustomer = this.checkCustomerAccess();
    if (this.isAuthorised) {
      this.getNotificationsCount();
      this.intervalidNotif = setInterval(
        function() {
          this.getNotificationsCount();
        }.bind(this),
        5000
      );
    }
  },
  created() {
    // if (process.env.NODE_ENV === "production") {
    //   this.$router.replace(this.$route.query.redirect || "/");
    // }
  }
};
</script>
