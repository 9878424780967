import Vue from "vue";
import Vuex from "vuex";
import { getAddConditions, getOneOrderTicket, getServices } from "@/api";

// Modules
import settingsGeneralStore from "./settingsGeneralStore";
import settingsNotificationsStore from "./settingsNotificationsStore";
import settingsPaymentsStore from "./settingsPaymentsStore";
import AllNotifsStore from "./AllNotifsStore";
import ChatStore from "./ChatStore";
import UsersListsStore from "./UsersListsStore";

import PackingSlipContractModal from "./PackingSlipContractModal";
import ServiceReportContractModal from "./ServiceReportContractModal";
import InvoiceContractModal from "./InvoiceContractModal";
import QuotationContractModal from "./QuotationContractModal";
import PurchaseOrderSt1ContractModal from "./PurchaseOrderSt1ContractModal";
import PurchaseOrderSt2ContractModal from "./PurchaseOrderSt2ContractModal";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AllNotifsStore,
    PackingSlipContractModal,
    ServiceReportContractModal,
    QuotationContractModal,
    PurchaseOrderSt1ContractModal,
    PurchaseOrderSt2ContractModal,
    InvoiceContractModal,
    settingsGeneralStore,
    settingsNotificationsStore,
    settingsPaymentsStore,
    ChatStore,
    UsersListsStore
  },
  state: {
    additionalConditions: [],
    servicesList: [],
    ticket: null,
    isAuthorised: false,
    userInfo: null
  },
  actions: {
    getOneOrderTicket({ commit }, orderId) {
      return getOneOrderTicket(orderId)
        .then(r => {
          commit("setTicketData", r.data ? r.data : []);
        })
        .catch(() => {
          document.location.href = "/";
        });
    },
    getAdditionalConditions({ commit }) {
      return getAddConditions()
        .then(r => {
          commit("setConditionsList", r.data ? r.data : []);
        })
        .catch(e => {
          console.error(e);
        });
    },
    getServicesList({ commit }) {
      return getServices()
        .then(r => {
          commit("setServicesList", r.data ? r.data : []);
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  mutations: {
    setConditionsList(state, data) {
      state.additionalConditions = data;
    },
    setTicketData(state, data) {
      state.ticket = data;
    },
    setServicesList(state, data) {
      state.servicesList = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    checkAuthorisation(state, data) {
      state.isAuthorised = data;
    }
  }
});
