import Router from "vue-router";
import Vue from "vue";
import _Layout from "@/pages/_Layout.vue";

// Sub routes
import accountRoutes from "./accountRoutes";
import projectsRoutes from "./projectsRoutes";
import ticketRoutes from "./ticketRoutes";

Vue.use(Router);
//
// async function isAuthorized(to: string, from: string, next: any) {
//   if (me()) {
//     next();
//   } else {
//     next("/login");
//   }
// }
//
// async function isUnauthorized(to: string, from: string, next: any) {
//   if (!me()) {
//     next("/search-expert");
//   } else {
//     next();
//   }
// }

const routes = [
  {
    path: "",
    component: _Layout,
    redirect: { name: "SearchExpert" },
    meta: {
      title: "ClueXpress"
    },
    children: [
      {
        path: "/auth",
        component: () => import(/* webpackChunkName: "Auth" */ "../pages/Auth/_Auth.vue"),
        children: [
          {
            path: "/login",
            name: "login",
            component: () => import(/* webpackChunkName: "Login" */ "../pages/Auth/Login.vue")
          },
          {
            path: "/registration",
            name: "registration",
            component: () =>
              import(/* webpackChunkName: "Registration" */ "../pages/Auth/Registration.vue")
          }
        ]
      },
      {
        path: "/confirmations",
        component: () =>
          import(/* webpackChunkName: "Confirmations" */ "../pages/Auth/ConfirmEmail.vue")
      },
      {
        path: "/reset_password",
        component: () =>
          import(/* webpackChunkName: "RestorePassword" */ "../pages/Auth/RestorePassword.vue")
      },
      {
        path: "/confirm_stripe_connect",
        component: () =>
          import(
            /* webpackChunkName: "StripeConfirmations" */ "../pages/Auth/StripeConfirmation.vue"
          )
      },
      {
        path: "/reauth",
        component: () =>
          import(
            /* webpackChunkName: "StripeConfirmations" */ "../pages/Auth/StripeConfirmation.vue"
          )
      },
      {
        path: "/search-expert",
        name: "SearchExpert",
        component: () =>
          import(/* webpackChunkName: "SearchExpert" */ "../pages/SearchExpert/SearchExpert.vue")
      },
      {
        path: "/create-order",
        name: "CreateOrder",
        component: () =>
          import(/* webpackChunkName: "CreateOrder" */ "../pages/CreateOrder/CreateOrder.vue")
      },
      {
        path: "/profile",
        name: "Profile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../pages/Profiles/User/Profile.vue")
      },
      {
        path: "/bid/:id",
        name: "Bid",
        component: () => import(/* webpackChunkName: "Bid" */ "../pages/Projects/Bid/Bid.vue")
      },
      {
        path: "/expert/:id",
        name: "Expert",
        component: () =>
          import(/* webpackChunkName: "Expert" */ "../pages/Profiles/Expert/Expert.vue")
      },
      {
        path: "/user/:id",
        name: "ShowUserProfile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../pages/Profiles/Shared/ShowUserProfile.vue")
        // },
        // {
        //   path: "/expert/:id",
        //   name: "Expert",
        //   component: () =>
        //     import(/* webpackChunkName: "Expert" */ "../pages/Profiles/Expert/Expert.vue")
        // },
        // {
        // path: "/customer/:id",
        // name: "CustomerProfile",
        // component: () =>
        //   import(
        //     /* webpackChunkName: "CustomerProfile" */ "../pages/Profiles/Customer/Customer.vue"
        //   )
      },

      accountRoutes,
      projectsRoutes,
      ticketRoutes
    ]
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "NotFound" */ "../pages/NotFound.vue"),
    name: "PageNotFound"
  }
];
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "ClueXpress";
  next();
});

export default router;
