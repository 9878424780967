import axios, { AxiosRequestConfig } from "axios";
import { Service } from "axios-middleware";
import Vue from "vue";

const service = new Service(axios);
const reservedUrls = [
  "/login",
  "/registration",
  "/search-expert",
  "/",
  "/account/settings",
  "/reset_password"
];
const statusCodesForRedirectToMainPage = [401, 502];

service.register({
  onSync(promise) {
    promise.catch(error => {
      const location = document.location.pathname;
      if (
        error.response === undefined ||
        statusCodesForRedirectToMainPage.includes(error.response.status)
      ) {
        if (!reservedUrls.includes(location) && !/\/user\//.test(location)) {
          document.location.pathname = "/login";
        }
      }
      throw error;
    });
    return promise;
  }
});

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json"
  },
  baseURL: process.env.VUE_APP_API_URL
});

export function checkCurrentLanguage() {
  let currentLanguage = "en";
  const selectedLanguage = Vue.$cookies.get("googtrans");
  if (selectedLanguage) {
    currentLanguage = selectedLanguage.split("/")[2];
  }

  return currentLanguage;
}

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }

  const currentLanguage = checkCurrentLanguage();

  if (config.params === undefined) {
    config.params = { locale: currentLanguage };
  } else {
    config.params["locale"] = currentLanguage;
  }
  return config;
});

export function setToken(token: string) {
  if (token) {
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.Authorization;
  }
}

// Authentication API
export function login(credentials: { session: { email?: string; password?: string } }) {
  return axiosInstance.post("/api/v1/login", credentials);
}
export function logout() {
  return axiosInstance.delete("/api/v1/logout");
}

export function resetPassword(params: { email?: string }) {
  return axiosInstance.post("/api/v1/passwords", params);
}

export function restorePassword(params: {
  reset_password_token?: string;
  password?: string;
  password_confirmation?: string;
}) {
  return axiosInstance.post("/api/v1/restore_password", params);
}
export function signup(credentials: {
  user: {
    email?: string;
    password?: string;
    password_confirmation?: string;
    terms_accepted?: boolean;
  };
}) {
  return axiosInstance.post("/api/v1/signup", credentials);
}

export function confirmEmail(token: { confirmation_token: string }) {
  return axiosInstance.post("/api/v1/confirm_email", token);
}

// Social auth
export function getSession() {
  return axiosInstance.get("/api/v1/current_session");
}

// Languages API
export function getLanguagesList() {
  return axiosInstance.get("/api/v1/languages");
}
export function addLanguage(params: { language: string }) {
  return axiosInstance.post("/api/v1/languages", params);
}

// Catalog API
export function getManufacturersList(params: { title: string }) {
  return axiosInstance.get("/api/v1/catalog/manufacturers", { params });
}
export function createManufacturerOrBrand(params: {
  manufacturer_id: string;
  manufacturer_title: string;
  brand_title: string;
}) {
  return axiosInstance.post("/api/v1/catalog/manufacturers", params);
}

export function getManufacturersBrandsList(params: {
  manufacturer_id?: string | number;
  title?: string;
}) {
  return axiosInstance.get("/api/v1/catalog/brands", { params });
}

export function getMachineModelsList(params: {
  manufacturer_id?: string | number;
  brand_id?: string | number;
  equipment_machine_id?: string | number;
  title?: string;
}) {
  return axiosInstance.get("/api/v1/catalog/machine_models", { params });
}

export function getNodeModelsList(params: {
  manufacturer_id?: string | number;
  brand_id?: string | number;
  equipment_node_id?: string | number;
  title?: string;
}) {
  return axiosInstance.get("/api/v1/catalog/node_models", { params });
}

export function getEquipmentNodes(params: {
  parent_id?: string | number;
  type?: "equipment_class" | "equipment_kind" | "equipment_group" | "equipment_type";
  title?: string;
}) {
  return axiosInstance.get("/api/v1/catalog/nodes", { params });
}

export function getEquipmentMachines(params: {
  parent_id?: string | number;
  type?: "equipment_class" | "equipment_kind" | "equipment_group" | "equipment_type";
  title?: string;
}) {
  return axiosInstance.get("/api/v1/catalog/machines", { params });
}

export function deleteNodeModel(id: string | number) {
  return axiosInstance.delete("/api/v1/catalog/node_models/" + id);
}

export function deleteLicenseExpert(id: string | number) {
  return axiosInstance.delete("/api/v1/licenses/destroy_for_expert/" + id);
}
export function deleteLicenseTechnic(id: string | number) {
  return axiosInstance.delete("/api/v1/licenses/destroy_for_technik/" + id);
}

export function deleteMachineModel(id: string | number) {
  return axiosInstance.delete("/api/v1/catalog/machine_models/" + id);
}

export function createNodeModel(data: {
  node_model: {
    manufacturer_title: string;
    brand_title: string;
    title: string;
  };
}) {
  return axiosInstance.post("/api/v1/catalog/node_models", data);
}

export function createMachineModel(data: {
  machine_model: {
    manufacturer_title: string;
    brand_title: string;
    title: string;
    equipment_machine_id: string | number;
  };
}) {
  return axiosInstance.post("/api/v1/catalog/machine_models", data);
}

// User Profiles
export function getEducationByUserId(params: { user_id?: string | number }) {
  return axiosInstance.get("/api/v1/user_educations/list_by_user_id?user_id=", { params });
}
export function getAllUserProfiles() {
  return axiosInstance.get("/api/v1/user_profiles");
}

export function getAllUserEducation() {
  return axiosInstance.get("/api/v1/user_educations");
}
export function getUserEducationList() {
  return axiosInstance.get("/api/v1/user_educations/list");
}
export function deleteUserEducation(id) {
  return axiosInstance.delete("/api/v1/user_educations/" + id);
}
export function createUserEducation(data: { kind: number; name: any; user_profile_id: any }) {
  return axiosInstance.post("/api/v1/user_educations", data);
}

export function getAllUserSpecializations() {
  return axiosInstance.get("/api/v1/user_specializations");
}
// General Profiles

export function updateGeneralProfile(data: {
  avatar_url: any;
  name: any;
  bio: any;
  user_location: any;
  user_languages: any;
  user_contacts: any;
}) {
  return axiosInstance.put("/api/v1/general_profiles", data);
}

// Customer Profiles
export function getCustomerProfile(id: string | number) {
  return axiosInstance.get("/api/v1/customer_profiles/" + id);
}

export function updateCustomerProfile(data: { user_company: any; insurance: any }) {
  return axiosInstance.put("/api/v1/customer_profiles", data);
}

// Expert Profiles

export function getExpertProfile(id: string | number) {
  return axiosInstance.get("/api/v1/experts/" + id);
}
export function getAllExpertProfiles(params: { page?: number | string }) {
  return axiosInstance.get("/api/v1/experts", { params });
}
export function updateExpertProfile(data: {
  working_area: any;
  user_specializations: any;
  user_educations: any;
  additional_info: any;
  licenses_ids: any;
  node_model_ids: any;
  machine_model_ids: any;
}) {
  return axiosInstance.put("/api/v1/expert_profiles", data);
}
// Technician Profiles

export function getTechnicianProfile(id: string | number) {
  return axiosInstance.get("/api/v1/technician_profiles/" + id);
}
export function updateTechnicianProfile(data: {
  working_area: any;
  user_specializations: any;
  user_educations: any;
  additional_info: any;
  skills_ids: any;
  licenses_ids: any;
}) {
  return axiosInstance.put("/api/v1/technician_profiles", data);
}

export function deleteTechSkill(id) {
  return axiosInstance.delete("/api/v1/skills/" + id);
}
// Person Profiles

export function getProfileIDs(id: string | number) {
  return axiosInstance.get("/api/v1/users/" + id);
}
// Licenses
export function getAllLicenses(title) {
  title = title ? title : "";
  return axiosInstance.get("/api/v1/licenses?title=" + title);
}
// create New Licenses
export function createLicenses(data: { name: string }) {
  return axiosInstance.post("/api/v1/licenses", data);
}

// Tools and Skills
export function getAllToolsAndSkills(speciality) {
  return axiosInstance.get("/api/v1/skills?speciality=" + speciality);
}

// Conditions
export function getAddConditions() {
  return axiosInstance.get("/api/v1/additional_conditions");
}

// Services
export function getServices() {
  return axiosInstance.get("/api/v1/services");
}

// Assets API
export function getPresignedLink(
  fileParams: {
    file_name: string;
    file_type: string;
    file_instance_name: string;
  },
  file
) {
  // const headers = {
  //   Authorization: "",
  //   Accept: "*/*",
  //   "Content-Encoding": "base64",
  //   "Content-Type": "multipart/form-data"
  // };
  return axiosInstance
    .get("/api/v1/presigned_link", {
      params: fileParams
    })
    .then(r => {
      return fetch(decodeURI(r.data.link), {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: file
      });
    });
}

export function createOrder(data) {
  return axiosInstance.post("/api/v1/orders", data);
}

export function getActiveOrders(params: {
  state: "new" | "in_progress" | "done";
  page?: number | string;
}) {
  return axiosInstance.get("/api/v1/tickets/orders", { params });
}

export function getDiagnosticTicketsList() {
  return axiosInstance.get(`/api/v1/quote_requests/get_service_reports`);
}

export function getArchiveTickets(sortBy = "asc", page = 1) {
  return axiosInstance.get(`/api/v1/tickets/archives?sorting_by_date=${sortBy}&page=${page}`);
}

export function getAllNotifs() {
  return axiosInstance.get("/api/v1/inapp_notifications");
}

export function readNotification(id: string | number) {
  return axiosInstance.put(`/api/v1/inapp_notifications/${id}/read`);
}

export function deleteNotification(id) {
  return axiosInstance.delete(`/api/v1/inapp_notifications/${id}/`);
}

// Settings
export function getSettingsNotifsFlags() {
  return axiosInstance.get("/api/v1/notification_flags");
}
export function updateSettingsNotifsFlags({ id, active }) {
  return axiosInstance.put(`/api/v1/notification_flags/${id}`, { id, active });
}

export function getSettingsChanels() {
  return axiosInstance.get("/api/v1/notification_channel_settings");
}
export function updateChanels(params) {
  return axiosInstance.put("/api/v1/notification_channel_settings", params);
}
export function sendStripeToken(token) {
  return axiosInstance.post("/api/v1/stripe_payment_cards", { card_token: token });
}
export function getAllPaymentCards() {
  return axiosInstance.get("/api/v1/payment_cards");
}

export function getStripeAccountStatus() {
  return axiosInstance.get("/api/v1/finance_account_status");
}
export function getStripeUpdateLink() {
  return axiosInstance.get("/api/v1/finance_account");
}

export function setPrimaryCard(cardId: string | number) {
  return axiosInstance.put(`/api/v1/stripe_payment_cards/${cardId}/set_primary`);
}
export function removeCard(cardId: string | number) {
  return axiosInstance.delete(`/api/v1/stripe_payment_cards/${cardId}`);
}

export function sendStripeAccount(data) {
  return axiosInstance.post("/api/v1/stripe_accounts", data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function getFinanceAccount(userId) {
  return axiosInstance.get("/api/v1/finance_account", { params: { user_id: userId } });
}

export function sendChangePassData({ oldPass, newPass, confPass }) {
  const params = { current_password: oldPass, password: newPass, password_confirmation: confPass };
  return axiosInstance.post("/api/v1/change_password", params);
}

export function getOneOrderTicket(id) {
  return axiosInstance.get("/api/v1/order_tickets/" + id);
}

export function archiveOrder(id) {
  return axiosInstance.put("/api/v1/order_tickets/" + id, { event: "archive" });
}

export function requestWarranty(form) {
  return axiosInstance.post("/api/v1/warranty_requests", form);
}

export function getActiveBids(params: {
  state: "new" | "in_progress" | "done";
  page?: number | string;
}) {
  return axiosInstance.get("/api/v1/tickets/bids", { params });
}

export function getOneBid(id) {
  return axiosInstance.get("/api/v1/tickets/bids/" + id);
}

export function getConversation(id: string | number) {
  return axiosInstance.get(`/api/v1/chat/conversations/${id}`);
}

export function sendMessage(id: string | number, data) {
  return axiosInstance.post(`/api/v1/chat/conversations/${id}/messages`, data);
}

// Quotations

export function createQuotation(data) {
  return axiosInstance.post("/api/v1/quotations", data);
}

export function getQuotationForTicket(id) {
  return axiosInstance.get("/api/v1/tickets/orders/" + id + "/quotations");
}

export function getOneQuoteRequest(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}`);
}

export function getAppFee() {
  return axiosInstance.get(`/api/v1/fee_coefficient`);
}

export function manageQuoteState(quoteId, event) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}`, null, { params: { event } });
}

export function serviceReportsList(quoteId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteId}/service_reports`);
}

export function replaceContractor(quoteId, ticketId) {
  return axiosInstance.put(`/api/v1/order_tickets/${ticketId}/remove_contractor`, null, {
    params: { quote_request_id: quoteId }
  });
}

export function acceptCookies() {
  return axiosInstance.put(`/api/v1/accept_cookies/`, { cookies_accepted: true });
}

export function createUpdatePurchaseOrder(quoteId, form) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/purchase_order`, form);
}

export function declineUnsignedPO(quoteId) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/decline_purchase_order`);
}

export function signPurchaseOrder(qrid) {
  return axiosInstance.put(`/api/v1/quote_requests/${qrid}/sign_purchase_order`);
}

export function payInvoice(iid) {
  return axiosInstance.post(`/api/v1/payments`, { invoice_id: iid });
}

export function getInvoice(quoteId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteId}/invoice`);
}
export function createInvoice(quoteId, form) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/invoice`, form);
}

export function createServiceReport(quoteId, form) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/service_report`, form);
}

export function updateServiceReport(quoteId, form) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/service_report`, form);
}

export function acceptServiceReport(quoteId, form) {
  return axiosInstance.put(`/api/v1/quote_requests/${quoteId}/accept_report`, form);
}

export function getQuotationPDF(quotationId) {
  return axiosInstance.get(`/api/v1/quotations/${quotationId}/show_pdf`, {
    responseType: "arraybuffer"
  });
}

export function getQuoteRequestPDF(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}/show_pdf`, {
    responseType: "arraybuffer"
  });
}

export function getPurchaseOrderPDF(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}/purchase_order/show_pdf`, {
    responseType: "arraybuffer"
  });
}

export function getServiceReportPDF(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}/service_report/show_pdf`, {
    responseType: "arraybuffer"
  });
}

export function getDiagnosticReportPDF(quoteRequestId, id) {
  return axiosInstance.get(
    `/api/v1/quote_requests/${quoteRequestId}/service_report/diagnostic_pdf/${id}`,
    {
      responseType: "arraybuffer"
    }
  );
}

export function getInvoicePDF(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}/invoice/show_pdf`, {
    responseType: "arraybuffer"
  });
}

export function getDownloadRequestPDF(quoteRequestId) {
  return axiosInstance.get(`/api/v1/quote_requests/${quoteRequestId}/show_pdf`, {
    responseType: "arraybuffer"
  });
}
