import {
  getSettingsNotifsFlags,
  updateSettingsNotifsFlags,
  getSettingsChanels,
  updateChanels
} from "@/api";

export default {
  namespaced: true,
  state: {
    notifs: [],
    options: []
  },
  actions: {
    getSettingsNotifsFlags({ commit }) {
      return new Promise(resolve => {
        getSettingsNotifsFlags().then(resp => {
          commit("setNotif", resp.data.notification_flags);
          resolve();
        });
      });
    },
    updateNotifFlag({ commit }, { id, active }) {
      return new Promise(resolve => {
        updateSettingsNotifsFlags({ id, active }).then(resp => {
          commit("setOneNotif", resp.data.notification_flag);
          resolve();
        });
      });
    },
    getSettingsChanels({ commit }) {
      return new Promise(resolve => {
        getSettingsChanels().then(resp => {
          commit("setOptions", resp.data.notification_channel_setting);
          resolve();
        });
      });
    },
    updateChanels({ commit }, params) {
      return new Promise(resolve => {
        updateChanels(params).then(resp => {
          commit("setOptions", resp.data.notification_channel_setting);
          resolve();
        });
      });
    }
  },
  mutations: {
    setNotif(state, arr) {
      state.notifs = arr;
    },
    setOneNotif(state, obj) {
      for (let i = 0, lim = state.notifs.length; i < lim; i += 1) {
        if (state.notifs[i][0] == obj.id) {
          state.notifs[i] = [obj.id, obj.event, obj.active];
          break;
        }
      }
    },
    setOptions(state, obj) {
      state.options = obj;
    }
  }
};
