import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import CountryFlag from "vue-country-flag";
import { autologin } from "./auth/auth";

import "./main.css";
import VueGooglePlaces from "vue-google-places";
import VueCookies from "vue-cookies";
import VueCarousel from "vue-carousel";
import { startCase } from "lodash";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueCarousel);

Vue.use(VueGooglePlaces);

Vue.use(VueCookies);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC7LkMKuhjYILldg1TBYsT4cvJNw6Rfglk",
    libraries: "places", // necessary for places input
    region: "uk,en,ca"
  }
});

// Vue.use(vuetify);
Vue.component("country-flag", CountryFlag);

Vue.config.productionTip = false;

Vue.filter("startCase", function(value) {
  if (!value) return "";
  value = value.toString();
  return startCase(value);
});

Vue.filter("currency", function(value) {
  return parseFloat(value).toFixed(2);
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

autologin();
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
