<template>
  <v-menu left bottom offset-y class="abc">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="ml-2">
        <img :src="flagIcon" class="flagIcon" alt="flag" />
      </v-btn>
    </template>
    <Translator :countries="countries" @on-country-click="changeFlag" />
  </v-menu>
</template>

<script>
import { Translator } from "vue-google-translate";

export default {
  name: "TranslateModal",
  components: {
    Translator
  },
  data() {
    return {
      lang: "en",
      currentLanguage: "en",
      countries: [
        {
          code: "en|uk",
          flagIconUrl: require("@/assets/icons/langs/uk.png"),
          title: "Ukrainian"
        },
        {
          code: "en|zh-CN",
          flagIconUrl: require("@/assets/icons/langs/zh-CN.png"),
          title: "Chinese (Simplified)"
        },
        {
          code: "en|en",
          flagIconUrl: require("@/assets/icons/langs/en.png"),
          title: "English"
        },
        {
          code: "en|fr",
          flagIconUrl: require("@/assets/icons/langs/fr.png"),
          title: "French"
        },
        {
          code: "en|de",
          flagIconUrl: require("@/assets/icons/langs/de.png"),
          title: "German"
        },
        {
          code: "en|it",
          flagIconUrl: require("@/assets/icons/langs/it.png"),
          title: "Italian"
        },
        {
          code: "en|ru",
          flagIconUrl: require("@/assets/icons/langs/ru.png"),
          title: "Russian"
        },
        {
          code: "en|es",
          flagIconUrl: require("@/assets/icons/langs/es.png"),
          title: "Spanish"
        },
        {
          code: "en|iw",
          title: "Hebrew",
          flagIconUrl: require("@/assets/icons/langs/iw.png"),
          altText: "Hebrew language translation"
        }
      ]
    };
  },
  computed: {
    flagIcon() {
      return require("@/assets/icons/langs/" + this.lang + ".png");
    }
  },
  methods: {
    changeFlag() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      setTimeout(() => {
        self.lang = self.getCookieSelectedLang();
      }, 300);
    },
    getCookieSelectedLang() {
      const selectedLanguage = this.$cookies.get("googtrans");
      return selectedLanguage ? selectedLanguage.split("/")[2] : this.currentLanguage;
    }
  },
  mounted() {
    this.changeFlag();
  }
};
</script>
<style>
.v-menu__content.theme--light.menuable__content__active {
  background: rgb(255, 255, 255);
}

.language-item {
  background: rgb(255, 255, 255);
  padding: 10px;
}

.language-item .flag {
  height: 40px;
  width: 40px;
}

.flagIcon {
  width: 40px;
  height: 40px;
}

.language__text {
  text-transform: capitalize;
}
</style>
