import { createUpdatePurchaseOrder } from "@/api";
import BaseContractModal from "@/store/BaseContractModal";

export default {
  namespaced: true,
  state: {
    ...BaseContractModal().state
  },
  actions: {
    createForm({ state }) {
      return new Promise(resolve => {
        // const form = new FormData();
        // const general = "purchase_order[general]";
        // const urgency = "purchase_order[urgency]";
        // const requestedService = "purchase_order[requested_service]";
        // const partsAndMaterials = "purchase_order[parts_and_materials]";
        // const limitedWarranty = "purchase_order[limited_warranty]";
        // const handlingAndShipping = "purchase_order[handling_and_shipping]";
        // const summary = "purchase_order[summary]";

        // // form.append("purchase_order[quote_request_id]", state.quoteId);

        // form.append(`${general}[payment_delay]`, state.paymentDelay);
        // form.append(`${general}[payment_delay_in_days]`, state.paymentDelay);
        // form.append(`${general}[minimum_service_amount]`, state.minimumServiceAmount);
        // form.append(`${general}[estimated_service_time]`, state.estimatedServiceTime);
        // form.append(`${general}[working_hours_from]`, state.workScheduleFrom);
        // form.append(`${general}[working_hours_to]`, state.workScheduleTo);

        // form.append(`${urgency}[quantity]`, state.quantity);
        // form.append(`${urgency}[units]`, state.textHint);
        // console.log('state.requestedServices.selectedConditions1', state.requestedServices.selectedConditions)

        // if (
        //   state.requestedServices.selectedConditions &&
        //   state.requestedServices.selectedConditions.length
        // ) {

        //   console.log('state.requestedServices.selectedConditions2', state.requestedServices.selectedConditions)
        //   state.requestedServices.selectedConditions.forEach(item => {
        //     form.append(`${requestedService}[services][]`, item.id);
        //   });
        // }
        // form.append(`${requestedService}[service_count]`, state.requestedServices.labourQuantity);
        // form.append(`${requestedService}[service_unit_type]`, state.requestedServices.unitsOne == "hours" ? 1 : 0);
        // form.append(`${requestedService}[service_rate]`, state.requestedServices.hourlyPrice);
        // form.append(`${requestedService}[service_currency]`, state.requestedServices.unitsTwo == "per_hour" ? 1 : 0);

        // form.append(`${partsAndMaterials}[labour]`, state.partsMaterials.labourQuantity);
        // form.append(`${partsAndMaterials}[labour_units]`, state.partsMaterials.unitsOne);
        // form.append(`${partsAndMaterials}[price]`, state.partsMaterials.hourlyPrice);
        // form.append(`${partsAndMaterials}[price_units]`, state.partsMaterials.unitsTwo);

        // form.append(`${limitedWarranty}[labour]`, state.limitedWarranty.labourQuantity);
        // form.append(`${limitedWarranty}[labour_units]`, state.limitedWarranty.unitsOne);
        // form.append(`${limitedWarranty}[price]`, state.limitedWarranty.hourlyPrice);
        // form.append(`${limitedWarranty}[price_units]`, state.limitedWarranty.unitsTwo);
        // form.append(`${limitedWarranty}[warranty_term]`, state.limitedWarranty.warrantyTerm);

        // form.append(`${handlingAndShipping}[labour]`, state.handlingShipping.labourQuantity);
        // form.append(`${handlingAndShipping}[labour_units]`, state.handlingShipping.unitsOne);
        // form.append(`${handlingAndShipping}[price]`, state.handlingShipping.hourlyPrice);
        // form.append(`${handlingAndShipping}[price_units]`, state.handlingShipping.unitsTwo);
        // form.append(`${handlingAndShipping}[incoterms]`, state.handlingShipping.incoterms);

        // form.append(`${summary}[subtotal]`, state.prices.subtotal);
        // form.append(`${summary}[vat]`, state.prices.vat);
        // form.append(`${summary}[total]`, state.prices.total);

        // there is for testing 500 error
        if (+state.prices.platformFee === 0) {
          const fixedFee = 9.0;
          state.prices.platformFee = (+state.prices.subtotal * 0.12 + +fixedFee).toFixed();
        }

        const cost = +state.prices.total - +state.prices.vat - +state.prices.platformFee;
        const subtotal = cost >= 0 ? cost.toFixed().toString() : "0";

        const quatation = this.state.QuotationContractModal.serviceCurrency;
        const invoice = this.state.InvoiceContractModal.serviceCurrency;
        const currency = quatation !== "" ? quatation : invoice;

        const FORM_FROM_API = {
          purchase_order: {
            general: {
              minimum_service_amount: +state.minimumServiceAmount,
              estimated_service_time: +state.estimatedServiceTime,
              working_hours_from: state.workScheduleFrom,
              working_hours_to: state.workScheduleTo,
              payment_delay_in_days: +state.paymentDelay,
              payment_delay: +state.paymentDelay
            },
            requested_service: {
              services: state.requestedServices.selectedConditions.map(i => {
                return i.id + "";
              }),
              service_rate: +state.requestedServices.hourlyPrice,
              service_count: +state.requestedServices.labourQuantity,
              service_currency: currency == "CAD" ? 1 : 0,
              service_unit_type: state.requestedServices.unitsTwo == "per_hour" ? 1 : 0
            },
            urgency: { quantity: +state.quantity, units: state.textHint },
            summary: {
              subtotal: +subtotal,
              vat: +state.prices.vat,
              total: +state.prices.total
            },
            parts_and_materials: {
              labour: +state.partsMaterials.labourQuantity,
              labour_units: state.partsMaterials.unitsOne,
              price: +state.partsMaterials.hourlyPrice,
              price_units: "per_pcs"
            },
            limited_warranty: {
              labour: +state.limitedWarranty.labourQuantity,
              labour_units: state.limitedWarranty.unitsOne,
              price: +state.limitedWarranty.hourlyPrice,
              price_units: "per_pcs",
              warranty_term: +state.limitedWarranty.warrantyTerm
            },
            handling_and_shipping: {
              labour: +state.handlingShipping.labourQuantity,
              labour_units: state.handlingShipping.unitsOne,
              price: +state.handlingShipping.hourlyPrice,
              price_units: state.handlingShipping.unitsTwo,
              incoterms: state.handlingShipping.incoterms
            }
          }
        };

        resolve(FORM_FROM_API);
      });
    },
    sendCreatePurchaseOrder({ dispatch }, quoteId) {
      return dispatch("createForm").then(form => {
        return createUpdatePurchaseOrder(quoteId, form);
      });
    }
  },
  mutations: {
    ...BaseContractModal().mutations,
    setPurchaseOrderData(state, data) {
      const servicesList = data.requested_service.services || [];
      // GENERAL
      state.quoteId = data.quote_request_id;
      state.paymentDelay = data.general.payment_delay;
      state.minimumServiceAmount = data.general.minimum_service_amount;
      state.estimatedServiceTime = data.general.estimated_service_time;
      state.workScheduleFrom = data.general.working_hours_from;
      state.workScheduleTo = data.general.working_hours_to;
      // URGENCY START
      state.quantity = data.urgency.quantity;
      state.textHint = data.urgency.units;
      // Requested services
      state.requestedServices = {
        selectedConditions: servicesList,
        labourQuantity: data.requested_service.service_count,
        unitsOne: data.requested_service.service_unit_type,
        hourlyPrice: data.requested_service.service_rate,
        unitsTwo: data.requested_service.service_currency
      };
      // Parts and materials
      state.partsMaterials = {
        labourQuantity: data.parts_and_materials.labour,
        unitsOne: data.parts_and_materials.labour_units,
        hourlyPrice: data.parts_and_materials.price,
        unitsTwo: data.parts_and_materials.price_units
      };
      // Handling shipping
      state.handlingShipping = {
        labourQuantity: data.handling_and_shipping.labour,
        unitsOne: data.handling_and_shipping.labour_units,
        hourlyPrice: data.handling_and_shipping.price,
        unitsTwo: data.handling_and_shipping.price_units,
        incoterms: data.handling_and_shipping.incoterms
      };
      // Limited warranty
      state.limitedWarranty = {
        labourQuantity: data.limited_warranty.labour,
        unitsOne: data.limited_warranty.labour_units,
        hourlyPrice: data.limited_warranty.price,
        unitsTwo: data.limited_warranty.price_units,
        warrantyTerm: data.limited_warranty.warranty_term
      };
      // Summary
      state.prices = {
        subtotal: data.summary.subtotal,
        vat: data.summary.vat,
        total: data.summary.total,
        fixedFee: "9.00",
        fee: "0.12",
        platformFee: "0"
      };
    }
  }
};
