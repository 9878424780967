export default {
  path: "/ticket/:id",
  name: "Ticket",
  redirect: { name: "Quotations" },
  component: () => import(/* webpackChunkName: "Ticket" */ "../pages/Projects/Ticket/Ticket.vue"),
  children: [
    {
      path: "",
      name: "Quotations",
      component: () =>
        import(/* webpackChunkName: "Quotations" */ "../pages/Projects/Ticket/QuoteRequestList.vue")
    },
    {
      path: "quoterequest/:qid",
      name: "Quotation",
      component: () =>
        import(/* webpackChunkName: "Quotation" */ "../pages/Projects/Ticket/QuoteRequest.vue")
    }
  ]
};
