import { createInvoice } from "@/api";
import BaseContractModal from "@/store/BaseContractModal";

const countPrice = data => {
  const labour = !isNaN(data.labour) ? data.labour : 0;
  const price = !isNaN(data.price) ? data.price : 0;
  return (labour * price).toFixed();
};

export default {
  namespaced: true,
  state: {
    ...BaseContractModal().state
  },
  actions: {
    createForm({ state }) {
      return new Promise(resolve => {
        const form = new FormData();
        const general = "invoice[general]";
        const requestedService = "invoice[requested_service]";
        const partsAndMaterials = "invoice[parts_and_materials]";
        const limitedWarranty = "invoice[limited_warranty]";
        const handlingAndShipping = "invoice[handling_and_shipping]";
        const summary = "invoice[summary]";

        form.append("invoice[quote_request_id]", state.quoteId);

        form.append(`${general}[payment_delay]`, state.paymentDelay);

        if (
          state.requestedServices.selectedConditions &&
          state.requestedServices.selectedConditions.length
        ) {
          state.requestedServices.selectedConditions.forEach(item => {
            form.append(`${requestedService}[services][]`, item.id);
          });
        }
        form.append(`${requestedService}[service_count]`, state.requestedServices.labourQuantity);
        form.append(`${requestedService}[service_unit_type]`, state.requestedServices.unitsOne);
        form.append(`${requestedService}[service_rate]`, state.requestedServices.hourlyPrice);
        form.append(`${requestedService}[service_currency]`, state.requestedServices.unitsTwo);
        form.append(`${requestedService}[total_sum]`, state.requestedServices.servicePrice);

        form.append(`${partsAndMaterials}[labour]`, state.partsMaterials.labourQuantity);
        form.append(`${partsAndMaterials}[labour_units]`, state.partsMaterials.unitsOne);
        form.append(`${partsAndMaterials}[price]`, state.partsMaterials.hourlyPrice);
        form.append(`${partsAndMaterials}[price_units]`, state.partsMaterials.unitsTwo);
        form.append(`${partsAndMaterials}[total_sum]`, state.partsMaterials.price);

        form.append(`${limitedWarranty}[labour]`, state.limitedWarranty.labourQuantity);
        form.append(`${limitedWarranty}[labour_units]`, state.limitedWarranty.unitsOne);
        form.append(`${limitedWarranty}[price]`, state.limitedWarranty.hourlyPrice);
        form.append(`${limitedWarranty}[price_units]`, state.limitedWarranty.unitsTwo);
        form.append(`${limitedWarranty}[warranty_term]`, state.limitedWarranty.warrantyTerm);
        form.append(`${limitedWarranty}[total_sum]`, state.limitedWarranty.price);

        form.append(`${handlingAndShipping}[labour]`, state.handlingShipping.labourQuantity);
        form.append(`${handlingAndShipping}[labour_units]`, state.handlingShipping.unitsOne);
        form.append(`${handlingAndShipping}[price]`, state.handlingShipping.hourlyPrice);
        form.append(`${handlingAndShipping}[price_units]`, state.handlingShipping.unitsTwo);
        form.append(`${handlingAndShipping}[incoterms]`, state.handlingShipping.incoterms);
        form.append(`${handlingAndShipping}[total_sum]`, state.handlingShipping.price);

        if (+state.prices.platformFee === 0) {
          const fixedFee = 9.0;
          state.prices.platformFee = (+state.prices.subtotal * 0.12 + +fixedFee).toFixed();
        }

        const subtotal = (+state.prices.total - +state.prices.vat - +state.prices.platformFee)
          .toFixed()
          .toString();

        form.append(`${summary}[subtotal]`, subtotal);
        form.append(`${summary}[vat]`, state.prices.vat);
        form.append(`${summary}[total]`, state.prices.total);

        // there is for testing 500 error
        const currency = state.serviceCurrency;
        const FORM_FROM_API = {
          invoice: {
            payment_delay_in_days: +state.paymentDelay,
            services: state.requestedServices.selectedConditions.map(i => i.id + ""),
            service_count: +state.requestedServices.labourQuantity,
            service_unit_type: state.requestedServices.unitsOne,
            service_rate: +state.requestedServices.hourlyPrice,
            service_currency: currency,
            parts_and_materials: {
              price: +state.partsMaterials.hourlyPrice,
              labour: +state.partsMaterials.labourQuantity,
              price_units: currency,
              labour_units: state.partsMaterials.unitsOne,
              total_sum: +state.partsMaterials.price
            },
            handling_and_shipping: {
              price: +state.handlingShipping.hourlyPrice,
              labour: +state.handlingShipping.labourQuantity,
              price_units: currency,
              labour_units: state.handlingShipping.unitsOne,
              total_sum: +state.handlingShipping.price
            },
            limited_warranty: {
              price: +state.limitedWarranty.hourlyPrice,
              labour: +state.limitedWarranty.labourQuantity,
              price_units: currency,
              labour_units: state.limitedWarranty.unitsOne,
              warranty_term: state.limitedWarranty.warrantyTerm,
              total_sum: +state.limitedWarranty.price
            },
            incoterms: state.handlingShipping.incoterms,
            summary: {
              vat: +state.prices.vat,
              total: +state.prices.total,
              subtotal: +subtotal
            }
          }
        };
        resolve(FORM_FROM_API);
      });
    },
    sendInvoiceForm({ dispatch }, quoteId) {
      return dispatch("createForm").then(form => {
        return createInvoice(quoteId, form);
      });
    }
  },
  mutations: {
    ...BaseContractModal().mutations,
    setInvoiceData(state, data) {
      const servicesList = data.requested_service.services || [];
      // GENERAL
      state.quoteId = data.quote_request_id;
      state.paymentDelay = data.general.payment_delay;
      // Requested services
      state.requestedServices = {
        selectedConditions: servicesList,
        labourQuantity: data.requested_service.service_count,
        unitsOne: data.requested_service.service_unit_type,
        hourlyPrice: data.requested_service.service_rate,
        unitsTwo: data.requested_service.service_currency,
        servicePrice: countPrice({
          labour: data.requested_service.service_count,
          price: data.requested_service.service_rate
        })
      };
      // Parts and materials
      state.partsMaterials = {
        labourQuantity: data.parts_and_materials.labour,
        unitsOne: data.parts_and_materials.labour_units,
        hourlyPrice: data.parts_and_materials.price,
        unitsTwo: data.parts_and_materials.price_units,
        price: countPrice({
          labour: data.parts_and_materials.labour,
          price: data.parts_and_materials.price
        })
      };
      // Handling shipping
      state.handlingShipping = {
        labourQuantity: data.handling_and_shipping.labour,
        unitsOne: data.handling_and_shipping.labour_units,
        hourlyPrice: data.handling_and_shipping.price,
        unitsTwo: data.handling_and_shipping.price_units,
        incoterms: data.handling_and_shipping.incoterms,
        price: countPrice({
          labour: data.handling_and_shipping.labour,
          price: data.handling_and_shipping.price
        })
      };
      // Limited warranty
      state.limitedWarranty = {
        labourQuantity: data.limited_warranty.labour,
        unitsOne: data.limited_warranty.labour_units,
        hourlyPrice: data.limited_warranty.price,
        unitsTwo: data.limited_warranty.price_units,
        warrantyTerm: data.limited_warranty.warranty_term,
        price: countPrice({
          labour: data.limited_warranty.labour,
          price: data.limited_warranty.price
        })
      };
      // Summary
      state.prices = {
        subtotal: data.summary.subtotal,
        vat: data.summary.vat,
        total: data.summary.total,
        fee: "0.12",
        fixedFee: "9.00",
        platformFee: "0"
      };
    },
    setServicesPrice(state, data) {
      state.requestedServices.servicePrice = countPrice(data);
    },
    setPartsMaterialsPrice(state, data) {
      state.partsMaterials.price = countPrice(data);
    },
    setLimitedWarrantyPrice(state, data) {
      state.limitedWarranty.price = countPrice(data);
    },
    setHandlingShippingPrice(state, data) {
      state.handlingShipping.price = countPrice(data);
    }
  }
};
