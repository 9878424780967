import { uniqBy } from "lodash";
export default () => {
  return {
    state: {
      quoteId: "",
      isModalOpened: false,
      termsChecked: false,
      // GENERAL
      paymentDelay: "",
      ticketType: null,
      minimumServiceAmount: "",
      serviceCurrency: "",
      estimatedServiceTime: "",
      workScheduleFrom: "",
      workScheduleTo: "",
      // URGENCY START
      quantity: "",
      textHint: "",
      // Requested services
      requestedServices: {
        currentSelectedCondition: "",
        selectedConditions: [],
        labourQuantity: "",
        unitsOne: "",
        hourlyPrice: "",
        unitsTwo: "",
        servicePrice: ""
      },
      // Parts and materials
      partsMaterials: {
        labourQuantity: "",
        unitsOne: "pcs",
        hourlyPrice: "",
        unitsTwo: "",
        price: ""
      },
      // Handling shipping
      handlingShipping: {
        labourQuantity: "",
        unitsOne: "",
        hourlyPrice: "",
        unitsTwo: "",
        incoterms: "",
        price: ""
      },
      // Limited warranty
      limitedWarranty: {
        labourQuantity: "",
        unitsOne: "pcs",
        hourlyPrice: "",
        unitsTwo: "",
        warrantyTerm: "",
        price: ""
      },
      // Summary
      prices: {
        subtotal: "0",
        vat: "0",
        fixedFee: "9.00",
        total: "0.00",
        fee: "0.12",
        platformFee: "0"
      }
    },
    mutations: {
      closeModal(state) {
        state.isModalOpened = false;
      },
      openModal(state) {
        state.isModalOpened = true;
      },
      setQuoteId(state, value) {
        state.quoteId = value;
      },
      setTerms(state, value) {
        state.termsChecked = value;
      },

      // General
      setPaymentDelay(state, value) {
        state.paymentDelay = value;
      },
      setMinimumServiceAmount(state, value) {
        state.minimumServiceAmount = value;
      },
      setEstimatedServiceTime(state, value) {
        state.estimatedServiceTime = value;
      },
      setWorkScheduleFrom(state, value) {
        state.workScheduleFrom = value;
      },
      setWorkScheduleTo(state, value) {
        state.workScheduleTo = value;
      },
      setTicketType(state, value) {
        state.ticketType = value;
      },
      setServiceCurrency(state, value) {
        state.serviceCurrency = value;
      },

      // URGENCY START
      setQuantity(state, value) {
        state.quantity = value;
      },
      setTextHint(state, value) {
        state.textHint = value;
      },

      // Requested services
      addSelectedCondition(state) {
        state.requestedServices.selectedConditions.push(
          state.requestedServices.currentSelectedCondition
        );
        state.requestedServices.selectedConditions = uniqBy(
          state.requestedServices.selectedConditions,
          "id"
        );
      },
      delSelectedCondition(state, value) {
        state.requestedServices.selectedConditions.splice(value, 1);
      },
      setCurrentSelectedCondition(state, value) {
        state.requestedServices.currentSelectedCondition = value;
      },
      setLabourQuantity(state, value) {
        state.requestedServices.labourQuantity = value;
      },
      setUnitsOne(state, value) {
        state.requestedServices.unitsOne = value;
        state.requestedServices.unitsTwo = value === "hours" ? "per_hour" : "per_pcs";
      },
      setHourlyPrice(state, value) {
        state.requestedServices.hourlyPrice = value;
      },
      setUnitsTwo(state, value) {
        state.requestedServices.unitsTwo = value;
      },

      // Parts and materials
      setPartsMaterialsLabourQuantity(state, value) {
        state.partsMaterials.labourQuantity = value;
      },
      setPartsMaterialsUnitsOne(state, value) {
        state.partsMaterials.unitsOne = value;
        state.partsMaterials.unitsTwo = value === "hours" ? "per_hour" : "per_pcs";
      },
      setPartsMaterialsHourlyPrice(state, value) {
        state.partsMaterials.hourlyPrice = value;
      },
      setPartsMaterialsUnitsTwo(state, value) {
        state.partsMaterials.unitsTwo = value;
      },

      // Limited warranty
      setLimitedWarrantyLabourQuantity(state, value) {
        state.limitedWarranty.labourQuantity = value;
      },
      setLimitedWarrantyUnitsOne(state, value) {
        state.limitedWarranty.unitsOne = value;
        state.limitedWarranty.unitsTwo = value === "hours" ? "per_hour" : "per_pcs";
      },
      setLimitedWarrantyHourlyPrice(state, value) {
        state.limitedWarranty.hourlyPrice = value;
      },
      setLimitedWarrantyUnitsTwo(state, value) {
        state.limitedWarranty.unitsTwo = value;
      },
      setLimitedWarrantyTerm(state, value) {
        state.limitedWarranty.warrantyTerm = value;
      },

      // Handling shipping
      setHandlingShippingLabourQuantity(state, value) {
        state.handlingShipping.labourQuantity = value;
      },
      setHandlingShippingUnitsOne(state, value) {
        state.handlingShipping.unitsOne = value;
        state.handlingShipping.unitsTwo = value === "hours" ? "per_hour" : "per_pcs";
      },
      setHandlingShippingHourlyPrice(state, value) {
        state.handlingShipping.hourlyPrice = value;
      },
      setHandlingShippingUnitsTwo(state, value) {
        state.handlingShipping.unitsTwo = value;
      },
      setHandlingShippingIncoterms(state, value) {
        state.handlingShipping.incoterms = value;
      },

      // Summary
      setSubtotal(state, value) {
        state.prices.subtotal = value;
      },
      setPlatformFee(state, value) {
        state.prices.platformFee = value;
      },
      setVat(state, value) {
        state.prices.vat = value;
      },
      setTotal(state, value) {
        state.prices.total = value;
      },
      setFixedFee(state, value) {
        state.prices.fixedFee = value;
      },

      clearModalData(state) {
        state.isModalOpened = false;
        state.termsChecked = false;
        state.quoteId = "";
        state.ticketType = null;
        // GENERAL
        state.paymentDelay = "";
        state.minimumServiceAmount = "";
        state.serviceCurrency = "";
        state.estimatedServiceTime = "";
        state.workScheduleFrom = "";
        state.workScheduleTo = "";
        // URGENCY START
        state.quantity = "";
        state.textHint = "";
        // Requested services
        state.requestedServices = {
          currentSelectedCondition: "",
          selectedConditions: [],
          labourQuantity: "",
          unitsOne: "",
          hourlyPrice: "",
          unitsTwo: "",
          servicePrice: ""
        };
        // Parts and materials
        state.partsMaterials = {
          labourQuantity: "",
          unitsOne: "pcs",
          hourlyPrice: "",
          unitsTwo: "",
          price: ""
        };
        // Handling shipping
        state.handlingShipping = {
          labourQuantity: "",
          unitsOne: "",
          hourlyPrice: "",
          unitsTwo: "",
          incoterms: "",
          price: ""
        };
        // Limited warranty
        state.limitedWarranty = {
          labourQuantity: "",
          unitsOne: "pcs",
          hourlyPrice: "",
          unitsTwo: "",
          warrantyTerm: "",
          price: ""
        };
        // Summary
        state.prices = {
          subtotal: "0",
          vat: "0",
          total: "0.00",
          fixedFee: "9.00",
          fee: "0.12",
          platformFee: "0"
        };
      }
    }
  };
};
