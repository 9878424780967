export default {
  namespaced: true,
  state: {
    paymentDelay: "",
    serviceTime: "",
    currentSelectedCondition: "",
    // MOCK!
    selectedConditions: [
      { id: 1, name: "One" },
      { id: 2, name: "Two" }
    ],
    labourQuantity: "",
    unitsOne: "",
    hourlyPrice: "",
    unitsTwo: "",
    incoterms: "",
    prices: {
      subtotal: 0,
      vat: 0,
      total: 0
    }
  },
  actions: {},
  mutations: {
    setPaymentDelay(state, value) {
      state.paymentDelay = value;
    },
    setServiceTime(state, value) {
      state.serviceTime = value;
    },
    setCurrentSelectedCondition(state, value) {
      state.currentSelectedCondition = value;
    },
    setLabourQuantity(state, value) {
      state.labourQuantity = value;
    },
    setUnitsOne(state, value) {
      state.unitsOne = value;
    },
    setHourlyPrice(state, value) {
      state.hourlyPrice = value;
    },
    setUnitsTwo(state, value) {
      state.unitsTwo = value;
    },
    setIncoterms(state, value) {
      state.incoterms = value;
    }
  }
};
